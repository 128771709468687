import React from 'react'
import "./GradientLegend.scss"
import _ from 'lodash';


type Props = {
    min: string;
    max: string;
    colors?: string[];
  };


/**
 * React functional component for displaying a gradient legend.
 *
 * @param {string} min - the minimum value of the scale
 * @param {string} max - the maximum value of the scale
 * @param {string[]} colors - the colors of the gradient, in order from left to right
 * @return {ReactElement} the gradient legend element
 */
const GradientLegend: React.FC<Props> = ({min, max, colors = ["#313bff", "#0eeff6", "#f5d908", "#f2740c", "#eb1710"]}) => {
    const colorsString = _.join(colors, ', ');
    return <div className='fno-gradient-legend'>
        <div style={{height:"40px", borderRadius:"5px", background: "linear-gradient(to right, "+colorsString+")"}}></div>
              <div style={{display: "flex", justifyContent:"space-between"}}>
                <div>{min}</div>
                <div>{max}</div>
              </div>
    </div>

}

export default GradientLegend